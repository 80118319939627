import React from 'react';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import imagesLoaded from 'imagesloaded';
import paginationStyle from './pagination.st.css';
import s from './ProductList.scss';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {DataHook as ProductImagesDataHook} from '../../../common/components/ProductItem/ProductImage/ProductImage';
import {EmptyGallery} from './EmptyGallery/EmptyGallery';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {LoadMoreButton} from './LoadMoreButton/LoadMoreButton';
import {LoadMoreType, PaginationType, GridType, PaginationTypeName} from '../../../types/galleryTypes';
import {Pagination} from 'wix-ui-tpa/Pagination';
import {ProductItem} from '../../../common/components/ProductItem/ProductItem';
import {withGlobals} from '../../../globalPropsContext';
import {DEFAULT_MOBILE_PRODUCTS_COUNT} from '../../../constants';

export interface ProductListProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  hasFilters: boolean;
}

interface ProductListState {
  inBrowser: boolean;
}

@withGlobals
@withTranslations()
@autobind
export class ProductList extends React.Component<ProductListProps, ProductListState> {
  private readonly productItemRefs = [];
  private readonly listRef: React.RefObject<HTMLUListElement>;
  private imagesLoaded = false;
  private isInteractive = false;

  public constructor(props: ProductListProps) {
    super(props);

    this.listRef = React.createRef();
    this.state = {
      inBrowser: false,
    };
  }

  public componentDidMount(): void {
    this.setState({inBrowser: true}, () => {
      imagesLoaded(document.querySelectorAll(`[data-hook="${ProductImagesDataHook.Images}"]`), () => {
        this.imagesLoaded = true;
        this.reportLoad();
      });
    });
  }

  private reportLoad() {
    if (this.props.globals.isInteractive && this.imagesLoaded) {
      this.props.globals.appLoadBI.loaded();
    }
  }

  public componentDidUpdate(prevProps: IGalleryGlobalProps) {
    if (!this.isInteractive && this.props.globals.isInteractive) {
      this.isInteractive = true;
      /* istanbul ignore next: hard to test it */
      this.props.globals.updateLayout && this.props.globals.updateLayout();
      this.reportLoad();
    }

    if (this.shouldFocusOnNewlyDisplayedProduct(prevProps)) {
      this.productItemRefs[this.props.globals.focusedProductIndex].focus();
    }
  }

  public render() {
    const {products} = this.props.globals;
    return (
      <section data-hook="product-list" aria-label={this.props.t('galleryRegionSR')}>
        {products.length === 0 ? this.getEmptyList() : this.getProductList()}
      </section>
    );
  }

  private get maxProductsPerPage(): number {
    if (this.props.globals.isAutoGrid) {
      return this.props.globals.styleParams.numbers.gallery_productsCount;
    }
    if (this.props.globals.isMobile) {
      return DEFAULT_MOBILE_PRODUCTS_COUNT;
    }
    return this.props.globals.styleParams.numbers.galleryColumns * this.props.globals.styleParams.numbers.galleryRows;
  }

  private getEmptyList() {
    return <EmptyGallery hasFilters={this.props.hasFilters} />;
  }

  private shouldFocusOnNewlyDisplayedProduct(prevProps) {
    return (
      !this.props.globals.isFirstPage &&
      this.props.globals.focusedProductIndex !== prevProps.globals.focusedProductIndex
    );
  }

  private getNumberOfVisibleProducts(): number {
    if (this.gridType() === GridType.AUTO && this.props.globals.isFirstPage) {
      return this.props.globals.styleParams.numbers.gallery_productsCount;
    }

    if (this.props.globals.isFirstPage) {
      return this.maxProductsPerPage;
    }

    return this.props.globals.products.length;
  }

  private gridType() {
    return this.props.globals.styleParams.numbers.gallery_gridType;
  }

  private getProductList() {
    const {products, isMobile, isAutoGrid, styleParams} = this.props.globals;
    const className = isAutoGrid ? s.autoGrid : s.manualGrid;
    const nextProducts = products.slice(0, this.getNumberOfVisibleProducts());

    return (
      <>
        <ul
          ref={this.listRef}
          className={classNames(className, {[s.isMobile]: isMobile})}
          data-hook="product-list-wrapper">
          {nextProducts.map((product, index) => {
            return (
              <li key={`${product.id}-${styleParams.numbers.gallery_productSize}`}>
                <ProductItem index={index} product={product} innerRef={c => (this.productItemRefs[index] = c)} />
              </li>
            );
          })}
        </ul>
        {this.renderLoadMore()}
      </>
    );
  }

  private renderLoadMore() {
    const {loadMoreType} = this.props.globals;

    switch (loadMoreType) {
      case LoadMoreType.PAGINATION:
        return this.renderPagination();
      case LoadMoreType.BUTTON:
      default:
        return this.shouldShowLoadMoreButton() && this.getLoadMoreButton();
    }
  }

  private shouldShowLoadMoreButton(): boolean {
    const {
      globals: {isFirstPage, hasMoreProductsToLoad, totalProducts},
    } = this.props;

    if (isFirstPage) {
      return this.maxProductsPerPage < totalProducts;
    }
    return hasMoreProductsToLoad;
  }

  private getLoadMoreButton() {
    return <LoadMoreButton loadMoreClicked={this.loadMoreClicked} />;
  }

  private async loadMoreClicked() {
    this.props.globals.setProductsPerPage(this.maxProductsPerPage);
    await this.props.globals.loadMoreProducts(this.getNumberOfVisibleProducts());
  }

  private handlePagination({page}) {
    const {handlePagination} = this.props.globals;
    handlePagination(page);
    window.scrollTo({left: 0, top: this.listRef.current.offsetTop, behavior: 'smooth'});
  }

  private renderPagination() {
    const {
      currentPage,
      totalProducts,
      styleParams: {
        numbers: {gallery_paginationFormat},
        booleans: {gallery_paginationFirstLastArrows},
      },
      isMobile,
    } = this.props.globals;
    const totalPages = Math.ceil(totalProducts / this.maxProductsPerPage);
    const paginationMode: PaginationTypeName =
      isMobile || gallery_paginationFormat === PaginationType.COMPACT ? 'compact' : 'pages';

    return (
      <div data-hook="product-list-pagination" className={s.pagination}>
        <Pagination
          {...paginationStyle('root')}
          currentPage={currentPage}
          data-hook={`product-list-tpa-pagination`}
          onChange={this.handlePagination}
          paginationMode={paginationMode}
          showFirstLastNavButtons={gallery_paginationFirstLastArrows}
          showFirstPage
          showLastPage
          totalPages={totalPages}
        />
      </div>
    );
  }
}
